<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Pagos a Clientes')}}</h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" :scrollable="true" scrollHeight="500px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :disabled ="$store.state.loading" :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
							<span class="p-input-icon-left">
								<i class="pi pi-search"/>
								<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
							</span>
                            <Button :disabled ="$store.state.loading" :label="$t('Pendientes')" style="width: 130px;" icon="pi pi-users" class="p-button-secondary p-mr-2" @click="Pendiente()"/>
						</div>
					</template>
					<Column field="id" header="Nº" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.id}} 
						</template>
					</Column>
                    <Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 100px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}                        
                        </template>
                    </Column>
                    <Column field="usuario" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
							{{slotProps.data.cliente}}                    
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Forma de pago')" headerStyle="width: 150px">
                        <template #body="slotProps">
							{{slotProps.data['tipo_'+i18n.locale()]}}
                        </template>
                    </Column>
                    <Column :header="$t('Monto')" headerStyle="width: 150px">
                        <template #body="slotProps">
							{{moneda(slotProps.data.monto)}}
                        </template>
                    </Column>
					<Column field="observ" :header="$t('Observaciones')">
                        <template #body="slotProps">
                            {{slotProps.data.observ}}                              
                        </template>
                    </Column>

                    <!--
                    <Column :header="$t('Anexos')" headerStyle="width: 120px"> 
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data.firmado)" v-if="slotProps.data.poliza && slotProps.data.firmado"/>
                        </template>
                    </Column>
                    -->
                </DataTable>
			</div>
        </div>

		<Dialog v-model:visible="Diag" :header="$t('Pendientes')" :style="{width: size}" :modal="true">
			<br>
            <div class="p-fluid p-formgrid p-grid" v-if="!revision">
                <div class="p-col-12 p-md-12">
                    <DataTable :value="deudas" v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                        <Column field="vence"  :header="$t('Fecha')" headerStyle="width: 120px">
							<template #body="slotProps">
								{{slotProps.data.fecha}}
							</template>
						</Column>       
                        <Column field="nom_cliente" :header="$t('Nombre Completo')" :sortable="true">
							<template #body="slotProps">
								<div>{{slotProps.data.nom_cliente}}</div>     
							</template>
						</Column>       
                        <Column field="monto"  :header="$t('Deuda')" headerStyle="width: 150px">
							<template #body="slotProps">
								{{moneda((-1)*slotProps.data.monto)}}
							</template>
						</Column>    
                        <Column field="vence" :header="$t('Acciones')" headerStyle="width: 70px">
							<template #body="slotProps">
                                <Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-secondary" @click="Seleccionar(slotProps.data)"/>
							</template>
						</Column>
                    </DataTable>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" v-else>
                <div class="p-field p-col-12 p-md-2">
                    {{$t('Fecha')}}: <strong>{{revision.fecha}}</strong>
				</div>
                <div class="p-field p-col-12 p-md-8">
                    {{$t('Nombre Completo')}}:  <strong>{{revision.nom_cliente}}</strong>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    {{$t('Monto')}}: <strong>{{moneda((-1)*revision.monto)}}</strong>
                </div>
                <div class="p-field p-col-12 p-md-12">
						<Button :disabled ="$store.state.loading" :label="$t('Agregar Pago')" icon="pi pi-money-bill" class="p-button-info p-mr-2 p-mb-2" @click="CargarPago()" style="width: 200px;" v-if="revision.pagos.length==0"/>
						<DataTable :value="revision.pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column :header="$t('Fecha')">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column :header="$t('Tipo')">	
								<template #body="{data}">
									<div v-if="i18n.locale() == 'es'">{{data.tipo.nombre}}</div>
									<div v-if="i18n.locale() == 'en'">{{data.tipo.name}}</div>									
								</template>
							</Column>
							<Column :header="$t('Referencia')">	
								<template #body="{data}">
									{{data.referencia}} {{data.cash}}
                                    <img style="width: 100px;" :src="url.upload+data.img" v-if="data.img"/>
								</template>
							</Column>
							<Column :header="$t('Monto')">	
								<template #body="{data}">
                                    {{moneda(data.monto)}}
								</template>
							</Column>
                            <Column :header="$t('Eliminar')">
								<template #body="{data}">
                                    <Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="EliminarPago(data)"/>
                                </template>
                            </Column>
                            
						</DataTable>      
				</div>
				<div class="p-field p-col-12 p-md-12">
					<label>{{$t('Observaciones')}}: </label>
					<Textarea v-model="revision.observ" rows="2"/>
				</div>
                <div class="p-field p-col-6 p-md-2">
                    <Button :disabled ="$store.state.loading ||  parseFloat(pago_monto)<parseFloat(((-1)*revision.monto))" :label="$t('Procesar')" icon="pi pi-check" class="p-button-secondary" @click="Guardar()"/>
                    
                </div>
                <div class="p-field p-col-6 p-md-2">
                    <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="revision = null"/>                    
                </div>
            </div>
        </Dialog>
        <Dialog v-model:visible="Dialog" :style="{width: size}" :header="$t('Agregar Pago')" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-8">
                        <div v-for="(stats, index) in tipos" :key="index">
                            <RadioButton id="tipo" name="subcateg" :value="stats.code" v-model="pago.tipo"/>
                            <strong v-if="i18n.locale() == 'es'"> {{stats.nombre}}<br><br></strong>
                            <strong v-else> {{stats.name}}<br><br></strong>
                        </div>
					</div>
					<div class="p-col-4" v-if="pago.tipo">
                        <label>{{$t('Fecha')}}</label>
						<InputMask v-model="pago.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
					</div>
					<div class="p-col-12" v-if="pago.tipo">
						<div v-if="pago.tipo==2">
							<label>{{$t('Banco de Origen')}}</label>
							<Dropdown v-model="pago.banco_orig" :options="bancos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" />
						</div>
					</div>
					<div class="p-col-8" v-if="pago.tipo==4">
                        <label>{{$t('Nombre tarjetahabiente')}}</label>
						<InputText v-model="pago.tarj_nom" type="text"/>
					</div>
					<div class="p-col-4" v-if="pago.tipo==4">
                        <label>{{$t('4 últimos dígitos')}}</label>
						<InputMask v-model="pago.tarj_num" mask="9999"/>
					</div>

					<div class="p-col-7" v-if="pago.tipo>0 && pago.tipo<5">
                        <label>{{$t('Referencia')}}</label>
						<InputNumber v-model="pago.referencia" required="true" min="0" mode="decimal" :useGrouping="false"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo==5">
                        <label>{{$t('Observación')}}</label>
						<InputText v-model="pago.cash" type="text"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo">
                        <label>{{$t('Monto')}}</label>
                        <InputNumber v-model="pago.monto" required="true" min="1" :minFractionDigits="2"/>
					</div>
                    <div class="p-field p-col-12 p-md-4" v-if="pago.tipo<4 && pago.tipo>0">
                        <FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="token" :url="url.api+'upload.php'" accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(token)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small>
                    </div>
					<div class="p-field p-col-4 p-md-4" v-if="pago.tipo<4 && pago.tipo>0">
						<img style="width: 100px;" :src="url.upload+pago.img" v-if="pago.img"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button :disabled ="$store.state.loading" :label="$t('Agregar')" icon="pi pi-check" class="p-button-secondary" @click="Agregar()"/>
				<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>
	</div>
	

</template>

<script>
import API from "../service/API";
const Consulta = new API('Administracion','ClientPagos');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import Token from "uuid-token-generator";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            deudas: [],
            products: [],
			selectedCustomers: null,
			filters: {},
			url: null,
			revision: null,
			bancos: [],
			tipos: [],
			Diag: false,
			size: '70vw',
			pdf: null,
			year: null,
			mes: null,
            Dialog: false,
            pago_monto: 0,
            pago: {
                fecha: null,
                tipo: null,
                banco_orig: { "nombre": "Banco del Caribe (BDC)", "name": "Bank of the Caribbean (BDC)", "code": 0 },
                referencia: null,
                tasa: null,
                moneda: 0,
                monto: null,
                img: null,
				cash: null,
				tarj_num: null,
				tarj_nom: null
            },

		}
	},
	productService: null,
	eventService: null,
	created() {
		this.year = this.$store.state.year;
		this.mes = this.$store.state.mes;
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.pdf = tokgen.generate();
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
		VerIMG(data){
			this.imagen = data;
			this.DiagIMG = true;
		},
        Mostrar(){
			this.products = [];
            Consulta.Ini().then(result => {
                //this.$store.state.error = result.consult.deudas;
                this.deudas = result.consult.deudas;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.bancos = result.consult.bancos;
                this.tipos = result.consult.tipos;
            }); 
        },
        Pendiente(){
            this.revision = null;
			this.deudas = [];
			Consulta.Procesar('Deudas',{
				id: 0,
			}).then(response => {
				this.deudas = response.result;
                this.Diag = true;
			});
        },
        Seleccionar(data){
            var dat = data;
            dat.pagos = [];
			dat.observ = null;
			this.revision = {...dat};
		},
        CargarPago() {
            const tokgen = new Token();
            this.token = tokgen.generate();
            this.pago = {
                fecha: this.$store.state.fechaini,
                tipo: null,
                banco_orig: { "nombre": "Banco del Caribe (BDC)", "name": "Bank of the Caribbean (BDC)", "code": 0 },
                referencia: null,
                tasa: null,
                monto: (-1)*this.revision.monto,
                moneda: 0,
                img: null,
				cash: null,
				tarj_num: null,
				tarj_nom: null
            };
            this.Dialog = true;
        },
		EliminarPago(selec) {
            this.pago_monto = 0;
			this.revision.pagos = this.revision.pagos.filter(val => val !== selec);
            this.revision.pagos.forEach(element => {
                this.pago_monto = parseFloat(this.pago_monto) + parseFloat(element.monto);
            }); 
		},
		myUploader(img) {
			Consulta.Procesar('RevFoto',{
				foto: img,
			}).then(response => {
				if(response.result){
                    this.pago.img = response.result;
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
			});
		},
        Agregar(){
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha.length!=10) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
			} else if (this.pago.banco_orig == null && this.pago.tipo == 2) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el banco', life: 5000});
			} else if (this.pago.referencia == null && this.pago.tipo<4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia del pago', life: 5000});
			} else if (this.pago.monto == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			} else if (this.pago.img == null && this.pago.tipo<4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
			} else {
				this.Dialog = false;
				this.tipos.forEach(element => {
					if(element.code == this.pago.tipo){
						this.pago.tipo = element;
					}
				});
				this.revision.pagos.push(this.pago);
				this.pago_monto = parseFloat(this.pago_monto) + parseFloat(this.pago.monto);
            }
        },
        fdate(dato){
            const [day, month, year] = dato.split('/');
            return new Date(+year, +month - 1, +day);
        },
        Buscar(){
			if(this.mes && this.year){
				this.products = [];
				Consulta.Procesar('Buscar',{
					mes: this.mes.code,
					year: this.year.code,
					}).then(response => {
                        //this.$store.state.error = response;
						this.products = response.result;
					});
			}
        },
        Guardar(){
			var pagos = this.revision.pagos;
			pagos.forEach(element => {
				element.fecha = this.fdate(element.fecha);
			});
			Consulta.Procesar('INS',{
				product: this.revision,
				pagos: pagos,
				}).then(response => {
                    //this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.revision = null;
                        this.Diag = false;
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                });
        },
        Ver(data){
            const doc = new PDF();
			var datafinal = data;
			datafinal.deudas = datafinal.deudas.filter(val => val.monto>0);
            doc.Factura(data);
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
        VerPDF2(data){
            window.open(url.api+'upload/'+data, '_blank');
        },
		VIMG(data){
			window.open(data, '_blank');
		},
        close() {
            this.caso = null;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
	},
	computed: {

    }
}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
