<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Revisón de Pagos')}}</h3>
		</div>
		<div class="p-col-12"  v-if="!revision">
			<div class="card">
				<DataTable :value="facturas" :scrollable="true" scrollHeight="500px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Dropdown class="p-mr-2" v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :disabled ="$store.state.loading" :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
							<span class="p-input-icon-left">
								<i class="pi pi-search"/>
								<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
							</span>
						</div>
					</template>
					<Column :header="$t('Estatus')" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							<div v-if="slotProps.data.anulada">
								<Avatar icon="bi bi-shield-fill-x" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" />
								<strong>{{$t('Anulada')}}</strong>
							</div>
							<div v-else>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
								<strong v-if="!slotProps.data.pagada && i18n.locale() == 'es'">{{opciones[1].nombre}}</strong>
								<strong v-if="!slotProps.data.pagada && i18n.locale() == 'en'">{{opciones[1].name}}</strong>
								<strong v-if="slotProps.data.pagada && i18n.locale() == 'es'">{{opciones[2].nombre}}</strong>
								<strong v-if="slotProps.data.pagada && i18n.locale() == 'en'">{{opciones[2].name}}</strong>
							</div>
						</template>
					</Column>
					<Column field="id" header="Nº" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}} 
						</template>
					</Column>
                    <Column field="fechad" :header="$t('Fecha')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.fechad}}                        
                        </template>
                    </Column>
                    <Column field="cliente.codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <div v-if="!slotProps.data.anulada">
								{{slotProps.data.cliente.codigo}}
							</div>      
                        </template>
                    </Column>
                    <Column field="cliente.cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
							{{slotProps.data.usuario.cliente}}                    
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.usuario.nombre}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Monto')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
							<div v-if="slotProps.data.anulada">
								{{moneda(0)}}
							</div>
							<div v-else>
								<div v-if="slotProps.data.monto<0" style="color:red;">
									{{moneda(slotProps.data.monto)}}
								</div>
								<div v-else>
									{{moneda(slotProps.data.monto)}}
								</div>
							</div>
                        </template>
                    </Column>
                    <Column :header="$t('Pagos')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
							<div v-if="slotProps.data.anulada">
								{{moneda(0)}}
							</div>
							<div v-else>
								<div v-if="slotProps.data.recibido<0" style="color:red;">
									{{moneda(slotProps.data.recibido)}}
								</div>
								<div v-else>
									{{moneda(slotProps.data.recibido)}}
								</div>           
							</div>                   
                        </template>
                    </Column>
                    <Column :header="$t('Deudas')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
							<div v-if="slotProps.data.anulada">
								{{moneda(0)}}
							</div>
							<div v-else>
								<div v-if="slotProps.data.pendiente<0" style="color:red;">
									{{moneda(slotProps.data.pendiente)}}
								</div>
								<div v-else>
									{{moneda(slotProps.data.pendiente)}}
								</div>
							</div>
                        </template>
                    </Column>
                    <Column :header="$t('Revisar')" headerStyle="width: 120px">
                        <template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2" @click="Revisar(slotProps.data)" v-if="edit && slotProps.data.poliza"/> 
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)" v-if="slotProps.data.pagada"/>
                        </template>
                    </Column>
                    <Column :header="$t('Anexos')" headerStyle="width: 120px"> 
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data.firmado)" v-if="slotProps.data.poliza && slotProps.data.firmado"/>
							<FileUpload :chooseLabel="$t('PDF')" uploadLabel="Cargar" mode="basic" :name="pdf" :url="url.api+'uploadpdf.php'"  accept="pdf" :maxFileSize="4000000" @upload="myUploader(slotProps.data)" :auto="true" v-if="slotProps.data.poliza"/>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>{{$t('Cliente')}}</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-6">
								{{$t('Nombre Completo')}}:
								<strong>{{revision.cliente.nombre}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								{{$t('Correo')}}: <strong>{{revision.cliente.pcorreo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								{{$t('Teléfono móvil')}}: <strong>{{revision.cliente.celular}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								{{$t('Teléfono privado')}}: <strong>{{revision.cliente.tlflocal}}</strong>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>
							{{$t('Estatus')}}
							<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="revision.pagada"/>
							<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
							<strong v-if="!revision.pagada && i18n.locale() == 'es'">{{opciones[1].nombre}}</strong>
							<strong v-if="!revision.pagada && i18n.locale() == 'en'">{{opciones[1].name}}</strong>
							<strong v-if="revision.pagada && i18n.locale() == 'es'">{{opciones[2].nombre}}</strong>
							<strong v-if="revision.pagada && i18n.locale() == 'en'">{{opciones[2].name}}</strong>
						</h5> 
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-3">
								Nº:
								<strong>{{revision.id}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-3">
								{{$t('Fecha')}}: 
								<strong>{{revision.fechad}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-3">
								{{$t('Monto')}}: 
								<strong>{{moneda(revision.monto)}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-3">
								{{$t('Pagos')}}: 
								<strong>{{moneda(revision.recibido)}}</strong>
							</div>
						</div>
					</template>
				</Card>
			</div>
			<Button :disabled ="$store.state.loading" :label="$t('Cerrar')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-danger p-mr-2" @click="revision=null" v-if="revision.pagada"/>
		</div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>{{$t('Descripción')}}</h5>
					</template>
					<template #content>
                        <DataTable :value="revision.deudas" stripedRows responsiveLayout="scroll">
                            <Column :header="$t('Fecha')" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.fecha}}                              
                                </template>
                            </Column> 
							<Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
								<template #body="slotProps">
									{{slotProps.data.codigo}}                              
								</template>
							</Column>
							<Column :header="$t('Monto')" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									<div v-if="slotProps.data.monto<0" style="color:red;">
										{{moneda(slotProps.data.monto)}}
									</div>
									<div v-else>
										{{moneda(slotProps.data.monto)}}
									</div>
								</template>
							</Column>
                        </DataTable>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>{{$t('Pagos')}}</h5>
					</template>
					<template #content>
                        <DataTable :value="revision.pagos" stripedRows responsiveLayout="scroll">
                            <Column :header="$t('Fecha')">
                                <template #body="slotProps">
                                    {{slotProps.data.fechad}}                              
                                </template>
                            </Column> 
                            <Column :header="$t('Tipo')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">
										{{slotProps.data.tipo_nombre}}
									</div>
                                    <div v-if="i18n.locale() == 'en'">
										{{slotProps.data.tipo_name}}
									</div>
                                </template>
                            </Column> 
                            <Column :header="$t('Referencia')">
                                <template #body="slotProps">
									<div v-if="slotProps.data.monto>0"> 
										<div v-if="slotProps.data.referencia"> 
											{{slotProps.data.referencia}}
										</div>
										<div v-else> {{$t('Oficina Comercial')}} </div> 
											<div v-if="slotProps.data.img">
												<div v-if="slotProps.data.img.indexOf('.pdf')"> 
													<img style="width: 50px;" src="images/pdf.jpg"  v-if="slotProps.data.img && slotProps.data.tipo<4" @click="VerPDF2(slotProps.data.img)"/>
												</div>
												
												<div v-else>
													<img style="width: 100px;" :src="url.upload+slotProps.data.img" v-if="slotProps.data.img && slotProps.data.tipo<4" @click="VerIMG(slotProps.data.img)"/>
												</div>
											</div> 
										</div>  
									<div v-else> ---- </div>  
								</template>
                            </Column> 
                            <Column :header="$t('Monto')">
                                <template #body="slotProps">
                                    {{moneda(slotProps.data.monto)}}                              
                                </template>
                            </Column>                        
                            <Column :header="$t('Válida')">
                                <template #body="slotProps">
									<InputSwitch v-model="slotProps.data.verificado"/>
                                </template>
                            </Column>  		
						</DataTable>
					</template>
					<template #footer>
                        <Button :disabled ="$store.state.loading" :label="$t('Guardar')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-secondary p-mr-2" @click="Guardar"/>
                        <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-danger p-mr-2" @click="revision=null"/>
					</template>
				</Card>
			</div>
		</div>

		<Dialog v-model:visible="DiagIMG" header=" " :style="{width: size2}" :modal="true">
			<br>
			<img style="width: 300px;" :src="url.upload+imagen"/>
		</Dialog>

	</div>
	

</template>

<script>
import API from "../service/API";
const Consulta = new API('Administracion','RevPagos');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import Token from "uuid-token-generator";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            facturas: [],
			selectedCustomers: null,
			filters: {},
			url: null,
			revision: null,
			bancos: [],
			tipos: [],
			opcion: {code: 0, nombre: 'Todos', name: 'All'},
			opciones: [
				{code: 0, nombre: 'Todos', name: 'All'},
				{code: 1, nombre: 'Pendiente', name: 'Pending'},
				{code: 2, nombre: 'Aprobado', name: 'Approved'},
				{code: 3, nombre: 'Anulada', name: 'Void'}
			],
			DiagIMG: false,
			size2: '25vw',
			pdf: null,
			year: null,
			mes: null,
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.year = this.$store.state.year;
		this.mes = this.$store.state.mes;
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.pdf = tokgen.generate();
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
		VerIMG(data){
			this.imagen = data;
			this.DiagIMG = true;
		},
        Mostrar(){
			this.facturas = [];
            Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.bancos = result.consult.bancos;
                this.tipos = result.consult.tipos;
            }); 
        },
        Revisar(data){
			this.revision = {...data};
		},
        Ver(data){
			var datafinal = data;
			datafinal.deudas = datafinal.deudas.filter(val => val.monto>0);
            const doc = new PDF();
            doc.Factura(data);
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
        VerPDF2(data){
            window.open(url.api+'upload/'+data, '_blank');
        },
		myUploader(data) {
			Consulta.Procesar('RevPDF',{
				pdf: this.pdf,
				data: data,
				leng: this.i18n.locale(),
			}).then(response => {
				if(response.result){
					data.firmado =  response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('No fue procesada la solicitud'), 
						life: 3000
					});
				}
				const tokgen = new Token(256);
				this.pdf = tokgen.generate();
			});
		},
		VIMG(data){
			window.open(data, '_blank');
		},
        Buscar(){
			if(this.mes && this.year && this.opcion){
				this.facturas = [];
				Consulta.Procesar('Buscar',{
					opcion: this.opcion.code,
					mes: this.mes.code,
					year: this.year.code,
				}).then(response => {
					this.$store.state.error = response.result.facturas[0];
					this.facturas = response.result.facturas;
				});
			}
        },
        Guardar(){
			Consulta.Procesar('UPD',{
				factura: this.revision.id,
				pagos: this.revision.pagos,
				leng: this.i18n.locale(),
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
						this.revision = null;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                });
        },
        close() {
            this.caso = null;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
